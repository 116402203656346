var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap doc_req_pop",
      staticStyle: { width: "900px", height: "410px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont cod_cont_area" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(" 선내 위치 확인 방법 "),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", { staticClass: "mt5" }, [
            _c("span", [
              _c(
                "a",
                {
                  class: _vm.$ekmtcCommon.isEmpty(_vm.trmlAddress)
                    ? "button sm gray disabled"
                    : "button sm",
                  attrs: {
                    disabled: _vm.$ekmtcCommon.isEmpty(_vm.trmlAddress),
                    href: _vm.trmlAddress,
                    target: "_blank",
                  },
                },
                [_vm._v("터미널 홈페이지 연결")]
              ),
            ]),
          ]),
          _vm._m(0),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "normal_box" }, [
      _c("ol", [
        _c("li", [
          _vm._v(
            "각 터미널 홈페이지 – 컨테이너 번호 조회 – 선내 위치 조회 (Ship location/Stowage)"
          ),
        ]),
        _c("li", [
          _vm._v(
            "컨테이너 넘버 조회 – 숫자 6자리 중 끝 두 자리로 조회 가능합니다."
          ),
        ]),
        _c("li", [_vm._v("  • 0 또는 1로 시작 시 : UNDER DECK")]),
        _c("li", [_vm._v("  • 8로 시작 시 : ON DECK")]),
        _c("li", [
          _vm._v(
            "ex) 270710 – Under deck / 340308 - Under deck / 141186 – On deck"
          ),
        ]),
        _c("li", [
          _vm._v(
            "그 외 조회 불가한 컨테이너 확인 필요 시 수출 마감팀 (DOCCLL@EKMTC.COM) 으로 문의 부탁드립니다."
          ),
        ]),
        _c("li", [_vm._v("감사합니다.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }