<template>
  <div class="popup_wrap doc_req_pop" style="width:900px; height:410px;">
    <button class="layer_close" @click="$emit('close')">close</button>

    <div class="popup_cont cod_cont_area">
      <!-- popup_cont -->
      <h1 class="page_title">
        <!-- 선내 위치 확인 방법 -->
        선내 위치 확인 방법
      </h1>

      <div
        class="content_box"
      >
        <p class="mt5">
          <span><a :class="$ekmtcCommon.isEmpty(trmlAddress) ? 'button sm gray disabled' : 'button sm'" :disabled="$ekmtcCommon.isEmpty(trmlAddress)" :href="trmlAddress" :target="'_blank'">터미널 홈페이지 연결</a></span><!-- 컨테이너 반출 요청서 양식 다운 -->
        </p>

        <div class="normal_box">
          <ol>
            <li>각 터미널 홈페이지 – 컨테이너 번호 조회 – 선내 위치 조회 (Ship location/Stowage)</li>
            <li>컨테이너 넘버 조회 – 숫자 6자리 중 끝 두 자리로 조회 가능합니다.</li>
            <li>&nbsp;&nbsp;• 0 또는 1로 시작 시 : UNDER DECK</li>
            <li>&nbsp;&nbsp;• 8로 시작 시 : ON DECK</li>
            <li>ex) 270710 – Under deck / 340308 - Under deck / 141186 – On deck</li>
            <li>그 외 조회 불가한 컨테이너 확인 필요 시 수출 마감팀 (DOCCLL@EKMTC.COM) 으로 문의 부탁드립니다.</li>
            <li>감사합니다.</li>
          </ol>
        </div>
      </div><!-- content_box // -->
    </div>
  </div>
</template>

<script>

import commons from '@/api/services/commons'
import transCommon from '@/api/rest/trans/transCommon'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import { rootComputed } from '@/store/helpers'

export default {
  name: 'TeminalVesselLocation',
  components: {
    rootComputed,
    WinLayerPop,
    EDatePicker: () => import('@/components/common/EDatePicker')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          bkgNo: ''
        }
      }
    }
  },
  data () {
    return {
      nowDate: '',
      bookingInfo: {
        type: Object,
        default: null
      },
      trmlAddress: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.bkgNo)) {
        this.setDelegateYn(this.$props.parentInfo.bkgNo)
      }
    }
  },
  created () {
    this.initPop()
  },
  methods: {
    async initPop () {
      //bkgNo없으면 blNo가져오기 처리
      const parentInfo = this.parentInfo
      if (this.$ekmtcCommon.isEmpty(parentInfo.blNo) && this.$ekmtcCommon.isEmpty(parentInfo.bkgNo)) {
        const TH = this
        this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.004'), () => {
          TH.$emit('close')
        })
      }

      if (this.$ekmtcCommon.isNotEmpty(parentInfo.bkgNo)) {
        this.init()
      } else {
        const TH = this
        this.$ekmtcCommon.alertCallback(this.$t('msg.ONEX070G050.014'), () => {
          TH.$emit('close')
        })
      }
    },
    async init () {
      const TH = this
      const parentInfo = TH.parentInfo
      const trmlCd = parentInfo.polTrmlCd
      const params = {
             trmlCd: trmlCd
      }

      await transCommon.findTrmlVesselLocation(params)
      .then(res => {
        console.log('findTrmlVesselLocation res @@@@ ', res)
        const data = res.data
        TH.trmlAddress = data.cdNm
      })
    }
  }
}
</script>

<style scoped>
.doc_req_pop {width:910px; height: 600px; overflow-y: scroll;}
.doc_req_pop > .layer_close {z-index: 10;}
.div_cntr_area + .div_cntr_area {margin-top: 5px;}
.text_link {color: #000; padding-left: 2px;}
.text_link:hover {text-decoration: underline !important;}
.attdel {cursor: pointer;}
</style>
